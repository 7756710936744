import React, { useState } from "react";

// import Contact from "../contact/Contact";
import clock from "../../Asset/clock01.gif";
import { FaArrowRightLong } from "react-icons/fa6";
import { toast } from "react-toastify";
import success from "../../Asset/success.png";
import error from "../../Asset/error.png";
import { NavLink } from "react-router-dom";

const LanchingSection = () => {
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [isOpenSuccess, setIsOpenSuccess] = useState(false);
  const [isOpenError, setIsOpenError] = useState(false);

  const sendToZohoBigin = async () => {
    if (!lastName || !email) {
      setMessage("Please fill out all fields.");
      toast.error(message);

      return;
    }
    setLoading(true);
    setMessage("");

    const data = {
      data: [{ Last_Name: lastName, Email: email }],
    };

    try {
      const response = await fetch(
        "https://zerotyping.ai/backend/proxy/zoho",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      const result = await response.json();

      if (response.ok) {
        setMessage("Successfully added to the waiting list!");
        setIsOpenSuccess(true);
        setLastName("");
        setEmail("");
      } else {
        setMessage(
          `Error: ${result.message || "Unable to add to the waiting list."}`
        );
        setIsOpenError(true);
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
      setIsOpenError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className=" bg-transparent flex justify-center flex-col items-center lg:my-10 md:my-8 my-6 md:pb-0 py-6 lg:gap-6 md:gap-5 gap-1">
        <div>
          <img
            src={clock}
            className="lg:w-[14rem] md:w-[10rem] w-[6rem]"
            alt=""
          />
        </div>
        <div className="flex justify-center items-center flex-col lg:gap-6 md:gap-5 gap-2 mb-3 ">
          <p className="lg:text-[3rem] md:text-[2rem] sm:text-[1.5rem] text-[1.55rem] font-medium font-clash text-center">
            <span className="bg-gradient-to-r from-[#5ECCFF] to-[#407BFF] bg-clip-text text-transparent">
              Launching
            </span>{" "}
            Soon!
          </p>
          <p className="text-center lg:text-[2rem] md:text-[1.5rem] sm:text-[.9rem] text-[.8rem] ">
            Join our waiting list today, and be the first to
            <p>test our exciting new productivity solution.</p>
          </p>
        </div>
        <div className="font-montserrat">
          <div className="flex justify-center md:mx-0 mx-8 items-center flex-row lg:gap-4 md:gap-2.5 gap-1.5">
            <input
              className="bg-transparent lg:w-[full]  md:text-[1rem] sm:text-[.7rem] text-[.45rem] w-[50%] text-white py-2 px-3 border border-[#A9A9A9] outline-none md:rounded-[1.2rem] sm:rounded-lg rounded-md lg:h-[4rem] md:h-[3rem] h-2rem"
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
              placeholder="First Name"
            />
            <input
              className="bg-transparent lg:w-[full]  md:text-[1rem] sm:text-[.7rem] text-[.45rem] w-[50%] text-white py-2 px-3 border border-[#A9A9A9] outline-none md:rounded-[1.2rem] sm:rounded-lg rounded-md lg:h-[4rem] md:h-[3rem] h-2rem"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Email"
            />
            {/* <button
            onClick={sendToZohoBigin}
            disabled={loading}
            className="flex items-center justify-center lg:gap-3 md:gap-2 gap-1 lg:h-[4rem] md:h-[3rem] h-2rem md:py-0 py-[9px] w-[80%] md:rounded-[1.2rem] sm:rounded-lg rounded-md bg-gradient-to-r from-[#5ECCFF] to-[#407BFF] lg:px-6 md:px-2 px-1 lg:text-[1.2rem] md:text-[.9rem] sm:text-[.7rem] text-[.45rem] font-medium"
            >
            {loading ? (
              "Submitting..."
              ) : (
                <>
                Join our Waiting List!
                <FaArrowRightLong />
                </>
                )}
                </button> */}
            <button
              onClick={() => {
                sendToZohoBigin();
              }}
              disabled={loading}
              className="group relative cursor-pointer overflow-hidden whitespace-nowrap border-[#407BFF] border px-0 text-white [background:var(--bg)]  transition-all duration-300  flex items-center justify-center lg:gap-3 md:gap-2 gap-1 lg:h-[4rem] md:h-[3rem] h-2rem md:py-0 py-[8.7px] w-[80%] md:rounded-[1.2rem] sm:rounded-lg rounded-md"
              style={{
                "--spread": "90deg",
                "--shimmer-color": "#ffffff",
                "--speed": "2s",
                "--cut": "0.1em",
                "--bg": "#0d0d0d",
                // Your gradient
              }}
            >
              <div className="absolute inset-0 overflow-hidden">
                <div className="absolute inset-[-100%] rotate-gradient">
                  <div className="absolute inset-0 [background:conic-gradient(from_calc(270deg-(var(--spread)*0.5)),transparent_0,hsl(210_100%_70%/1)_var(--spread),transparent_var(--spread))]"></div>
                </div>
              </div>
              <div className="absolute [background:var(--bg)] md:rounded-[1.2rem] sm:rounded-lg rounded-md [inset:var(--cut)]"></div>
              <span className="z-10 flex items-center justify-center lg:gap-3 md:gap-2 gap-1 lg:px-6 md:px-2 px-1 lg:text-[1.2rem] md:text-[.9rem] sm:text-[.7rem] text-[.45rem] font-medium">
                {loading ? (
                  "Submitting..."
                ) : (
                  <>
                    Join our Waiting List!
                    <FaArrowRightLong />
                  </>
                )}
              </span>
            </button>
          </div>
        </div>
      </div>
      {isOpenSuccess && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
          <div className="bg-[#323232B2] backdrop-blur-md w-[21rem] p-5 flex flex-col justify-center items-center gap-4 rounded-xl">
            <div className=" w-[full] flex justify-center items-center flex-col gap-3">
              <img src={success} className="w-16 " alt="" />
              <span className=" capitalize text-[#00FF1E] font-semibold leading-none">
                success!
              </span>
              <span className=" text-center">
                Your submission was successful! You’ve been added to the waiting
                list and we’ll keep you updated with the latest news through the
                provided email address.
              </span>
            </div>
            <div className="w-full">
              <button
                onClick={() => setIsOpenSuccess(false)}
                className=" bg-gradient-to-r from-[#5ECCFF] to-[#407BFF] text-white w-full flex justify-center items-center leading-none py-3 rounded-xl "
              >
                Done
              </button>
            </div>
          </div>
        </div>
      )}
      {isOpenError && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-opacity-50 backdrop-blur-sm">
          <div className="bg-[#323232B2] backdrop-blur-md w-[21rem] p-5 flex flex-col justify-center items-center gap-4 rounded-xl">
            <div className=" w-[full] flex justify-center items-center flex-col gap-3">
              <img src={error} className="w-16 " alt="" />
              <span className=" capitalize text-[#f04349] font-semibold leading-none">
                error!
              </span>
              <span className=" text-center">
                Oops! Something went wrong. Please check your details and try
                again, or refresh the page and resubmit your information. If the
                error persists please contact support at{" "}
                <NavLink
                  to="mailto:support@zerotyping.ai"
                  className={"text-blue-500 "}
                  target="_blank"
                >
                  support@zerotyping.ai
                </NavLink>
              </span>
            </div>
            <div className="w-full">
              <button
                onClick={() => setIsOpenError(false)}
                className=" bg-gradient-to-r from-[#5ECCFF] to-[#407BFF] text-white w-full flex justify-center items-center leading-none py-3 rounded-xl "
              >
                Try Again
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LanchingSection;
