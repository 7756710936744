import React from "react";
import usa from "../../Asset/usa.png";
import germany from "../../Asset/germany.png";
import { FaArrowRightArrowLeft, FaMicrophone } from "react-icons/fa6";
import { FiX } from "react-icons/fi";
import { Typewriter } from "react-simple-typewriter";

const AITranslation = () => {
  return (
    <div className="w-full flex flex-col lg:gap-3 md:gap-2 lg:mt-4 md:mt-3  mt-1.5  sm:gap-1 gap-[4px] h-full">
      <p className="bg-[#313131] leading-none font-semibold  flex justify-center items-center lg:text-[14px] md:text-[9px] sm:text-[7px] text-[5px] md:font-medium w-max lg:mx-3 md:mx-2  mx-1 text-[white] lg:px-6 md:px-4 sm:px-2 px-1.5 lg:py-2 md:py-[4px] py-[2px] rounded-full">
        AI Translation
      </p>
      <div className="text-white flex-none flex justify-between items-center md:gap-3 gap-1 lg:mx-6 md:mx-4 mx-[6px] ">
        <p className="flex justify-center items-center  lg:gap-3 md:gap-2 gap-1 border-[#747474]/50 border-[.1px] lg:p-[6px] md:p-[4px] px-[3px] py-[2px]   rounded-full lg:text-[12px] md:text-[8px] sm:text-[7px] leading-none text-[5px]">
          <img
            src={usa}
            className="lg:w-[25%] w-[20%] lg:h-[25%] h-[20%]"
            alt=""
          />
          English
        </p>

        <p>
          <FaArrowRightArrowLeft className="lg:size-3 md:size-2 size-1" />
        </p>
        <p className="flex justify-center items-center  lg:gap-3 md:gap-2 gap-1 border-[#747474]/50 border-[.1px] lg:p-[6px] md:p-[4px] px-[3px] py-[2px]   rounded-full lg:text-[12px] md:text-[8px] sm:text-[7px] leading-none text-[5px]">
          German
          <img
            src={germany}
            className="lg:w-[25%] w-[20%] lg:h-[25%] h-[20%]"
            alt=""
          />
        </p>
      </div>
      <div className="flex-1 flex-grow flex flex-col justify-between lg:mx-6 md:mx-4 mx-[6px] lg:mb-4 md:mb-3  mb-1.5  text-white border-[#747474]/50 border-[.1px] lg:rounded-lg md:rounded-md rounded lg:p-3 md:p-2 p-1">
        <div className="flex flex-col lg:gap-1 md:gap-[3px] gap-2px">
          <div className="flex justify-between items-center">
            <div className="lg:text-[12px] md:text-[8px] sm:text-[7px] text-[6px]">
              German
            </div>
            <span>
              <FiX className="text-[#525A6D] lg:size-4 md:size-3 sm:size-[6px] size-[5px]" />
            </span>
          </div>
          <div className="lg:text-[12px] md:text-[7px] sm:text-[5px] text-[4px]">
            <Typewriter
              words={["Effortless voice typing!"]}
              loop={0}
              cursor
              cursorStyle="|"
              typeSpeed={90}
              deleteSpeed={50}
              delaySpeed={1000}
            />
          </div>
        </div>
        <div className="flex justify-between items-center lg:gap-2 md:gap-[6px] gap-[2px]">
          <span className="flex-none">
            <FaMicrophone className="lg:size-4 md:size-3 sm:size-[6px] size-[4px]" />
          </span>
          <div className="flex-1 flex-grow border-[#747474]/50 border-[.1px] lg:py-2 md:py-1 py-[3px]  lg:px-2 md:px-1 px-[2px] rounded-full lg:text-[12px] md:text-[7px] sm:text-[5px] text-[4px] leading-none">
            <Typewriter
              words={["Müheloses Tippen mit der Stimme!"]}
              loop={0}
              cursor
              cursorStyle="|"
              typeSpeed={70}
              deleteSpeed={30}
              delaySpeed={1000}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AITranslation;
