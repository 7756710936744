import React from "react";
import dasboard from "../../Asset/dasboard.png";
import AutomationAi from "../../Asset/Automation & Ai.png";
import Integrations from "../../Asset/Integrations.png";
import Grammar from "../../Asset/Grammar Checker.png";
import Plagiarism from "../../Asset/Plagiarism Checker.png";
import Paraphraser from "../../Asset/Paraphraser.png";
import AITranslation from "./AITranslation";
import Marquee from "react-fast-marquee";

const Dasboard = () => {
  return (
    <div className="flex justify-center items-center flex-col md:gap-12 gap-6 lg:my-36 sm:my-28 my-20">
      <h1 className="text-center lg:text-[3rem] md:text-[2rem] sm:text-[1.5rem] text-[1.4rem] font-medium font-clash md:my-10 my-6">
        Type Less,{" "}
        <span className="bg-gradient-to-r from-[#5ECCFF] to-[#407BFF] bg-clip-text text-transparent">
          Do More
        </span>{" "}
        - The AI <br /> that works for you.
      </h1>
      <div>
        <div className="w-full flex md:justify-center justify-end items-center font-montserrat relative ">
          <div className="md:w-[75%] w-[90%]  relative overflow-hidden md:overflow-visible">
            <div className="mr-[-30%] md:mr-0 ">
              <img
                src={dasboard}
                className="rounded-2xl  shadow-none md:shadow-[0_0_200px_rgba(94,204,255,0.1),0_0_100px_rgba(94,204,255,0.1),0_0_30px_rgba(94,204,255,0.1)]"
                alt=""
              />
            </div>
          </div>
          <div className="md:hidden block w-[50%] absolute md:h-[150%] h-[130%] bg-gradient-to-r to-[#0d0d0d]  from-[#1c1c1c0e] top-[-10%] right-0 z-10 "></div>
          <div className="md:w-[27%] w-[30%] md:h-[27%] h-[21%] flex flex-col justify-between items-start  bg-[#161616] border-[#747474]/50 border-[.1px] lg:rounded-[12px] md:rounded-[10px] rounded-md  text-black absolute top-[-6%] md:left-[37%] left-[55.5%]">
            <p className="bg-[#313131] flex justify-center items-center lg:text-[14px] md:text-[9px] sm:text-[7px] text-[5px] md:font-medium  w-max lg:m-4 md:m-3 m-1.5 font-semibold text-[white] lg:px-6 md:px-4 max-md:px-2 px-1 lg:py-2 md:py-[4px] py-[2px] leading-none rounded-full">
              Features
            </p>

            <Marquee direction="left" className="text-white  ">
              {/* large */}
              <div className="hidden lg:flex gap-3 lg:mb-3 md:mb-2 mb-1 lg:text-[14px] md:text-[10px] max-md:text-[7px] text-[6px] font-medium">
                <div className="flex ml-3 justify-center items-center gap-4 border-[#DFE5F9]/10 border px-9  py-[1.1rem]  sm:rounded-xl rounded">
                  <img
                    className="lg:w-7"
                    src={Plagiarism}
                    alt="Plagiarism Checker"
                  />
                  <p className="leading-none">Plagiarism Checker</p>
                </div>
                <div className="flex  justify-center items-center gap-4 border-[#DFE5F9]/10 border px-9  py-[1.1rem]  sm:rounded-xl rounded">
                  <img className="lg:w-8" src={Paraphraser} alt="Paraphraser" />
                  <p className="leading-none">Paraphraser</p>
                </div>

                <div className="flex  justify-center items-center gap-4 border-[#DFE5F9]/10 border px-9  py-[1.1rem]   sm:rounded-xl rounded">
                  <img className="lg:w-7" src={Grammar} alt="Grammar Checker" />
                  <p className="leading-none">Grammar Checker</p>
                </div>
              </div>
              {/* medium */}
              <div className="hidden md:flex lg:hidden gap-3 lg:mb-3 md:mb-2 mb-1 lg:text-[14px] md:text-[10px] max-md:text-[7px] text-[6px] font-medium">
                <div className="flex ml-3 justify-center items-center gap-4 border-[#DFE5F9]/10 border lg:px-8 px-6 lg:py-3 md:py-2 py-[6px] sm:rounded-xl rounded">
                  <img
                    className="lg:w-7 md:w-6 max-md:w-5 w-3"
                    src={Plagiarism}
                    alt="Plagiarism Checker"
                  />
                  <p className="leading-none">Plagiarism Checker</p>
                </div>
                <div className="flex  justify-center items-center gap-4 border-[#DFE5F9]/10 border lg:px-8 px-6 lg:py-3 md:py-2 py-[6px] sm:rounded-xl rounded">
                  <img
                    className="lg:w-8 md:w-7 max-md:w-6 w-4"
                    src={Paraphraser}
                    alt="Paraphraser"
                  />
                  <p className="leading-none">Paraphraser</p>
                </div>

                <div className="flex  justify-center items-center gap-4 border-[#DFE5F9]/10 border lg:px-8 px-6 lg:py-3 md:py-2  py-[6px] sm:rounded-xl rounded">
                  <img
                    className="lg:w-7 md:w-6 max-md:w-5 w-3"
                    src={Grammar}
                    alt="Grammar Checker"
                  />
                  <p className="leading-none">Grammar Checker</p>
                </div>
              </div>
              {/* samll */}
              <div className="text-white md:hidden block ">
                <div className="flex gap-2  mb-1  text-[5px] font-medium">
                  <div className="flex ml-2 justify-center items-center gap-2 border-[#DFE5F9]/10 border-[.5px]  py-[5px] px-2 rounded">
                    <img
                      className=" w-[10px]"
                      src={Plagiarism}
                      alt="Plagiarism Checker"
                    />
                    <p className="leading-none">Plagiarism Checker</p>
                  </div>
                  <div className="flex  justify-center items-center gap-2 border-[#DFE5F9]/10 border-[.5px]  py-[5px] px-2 rounded">
                    <img
                      className="w-[10px]"
                      src={Paraphraser}
                      alt="Paraphraser"
                    />
                    <p className="leading-none">Paraphraser</p>
                  </div>

                  <div className="flex  justify-center items-center gap-2 border-[#DFE5F9]/10 border-[.5px]   py-[5px] px-2 rounded">
                    <img
                      className=" w-[10px]"
                      src={Grammar}
                      alt="Grammar Checker"
                    />
                    <p className="leading-none">Grammar Checker</p>
                  </div>
                </div>
              </div>
            </Marquee>
          </div>
          <div className="md:w-[27%] sm:w-[27%] w-[34%] md:h-[50%] sm:h-[30%] h-[40%] md:scale-100 scale-110 flex flex-col justify-between items-start  bg-[#161616] border-[#747474]/50 border-[.1px] lg:rounded-[12px] md:rounded-[10px] rounded-md text-black absolute md:bottom-[-20%] md:right-[20%] sm:right-[40%] sm:bottom-[-12%] bottom-[-22%] right-[35%]">
            <AITranslation />
          </div>
          <div className=" md:w-[24%] w-[28%] absolute top-[47.5%] md:left-[5%] left-[3%]">
            <img src={AutomationAi} alt="" />
          </div>
          <div className=" md:w-[27%] w-[31%] absolute top-[30%] md:top-[30%] md:right-[6%] right-[2%]">
            <img src={Integrations} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dasboard;
