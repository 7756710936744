import React, { useRef } from "react";

const Slider = ({ activeTab, setActiveTab, tabs }) => {
  const containerRef = useRef(null);

  // Mouse and Touch Scroll Handlers
  const startScroll = (event) => {
    const container = containerRef.current;
    container.dataset.isDown = true;
    const pageX = event.type.includes("touch")
      ? event.touches[0].pageX
      : event.pageX;
    container.dataset.startX = pageX - container.offsetLeft;
    container.dataset.scrollLeft = container.scrollLeft;
  };

  const moveScroll = (event) => {
    const container = containerRef.current;
    // event.preventDefault();
    if (container.dataset.isDown === "true") {
      const pageX = event.type.includes("touch")
        ? event.touches[0].pageX
        : event.pageX;
      const x = pageX - container.offsetLeft;
      const walk = (x - container.dataset.startX) * 2;
      container.scrollLeft = container.dataset.scrollLeft - walk;
    }
  };

  const endScroll = () => {
    containerRef.current.dataset.isDown = false;
  };

  return (
    <>
      {tabs?.length > 0 ? (
        <div className="w-full md:hidden flex justify-center items-center relative overflow-x-auto scroll-smooth">
          {/* Scrollable Menu Container */}
          <div
            ref={containerRef}
            className="flex overflow-hidden gap-4 items-center scrollbar-hide px-[5%]"
            onMouseDown={startScroll}
            onMouseMove={moveScroll}
            onMouseUp={endScroll}
            onMouseLeave={endScroll}
            onTouchStart={startScroll}
            onTouchMove={moveScroll}
            onTouchEnd={endScroll}
          >
            {tabs.map((tab, index) => (
              <div key={index} className="flex-shrink-0 ">
                <div
                  className={`flex justify-center items-center w-[11.45rem] rounded-[1rem] h-[9rem] mb-3 ${
                    activeTab === tab.id
                      ? "bg-[#161616]  border border-[#888888]/50 "
                      : "bg-[#313131] "
                  } `}
                >
                  <div
                    onClick={() => setActiveTab(tab.id)}
                    className={`flex justify-between  pb-2 pt-3 cursor-pointer  items-center h-[calc(100%-2rem)] flex-col  ${
                      activeTab === tab.id ? "bg-[#222222]" : "bg-[#222222]"
                    }  rounded-[1rem]  m-4 w-full`}
                  >
                    <div className="flex-1 basis-3/5 flex items-center">
                      <div
                        className={`w-[4rem] h-[3.1rem]  rounded-[12.44px] p-[1px] ${
                          activeTab === tab.id
                            ? "bg-gradient-to-t from-[#3a3a3a20] to-[#5ECCFF90]"
                            : "bg-gradient-to-t from-[#FFFFFF0D] to-[#FFFFFF80]"
                        }`}
                      >
                        <div className=" bg-[#313131] w-full h-full rounded-[12px] ">
                          <div className="flex justify-center items-center h-full ">
                            <img className="w-5" src={tab.icon} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex-1 basis-2/5">
                      <div className="flex items-center justify-center h-full ">
                        <span className="w-[99.985%] text-center  font-montserrat text-[.7rem] capitalize font-semibold">
                          {tab.label1}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Slider;
