import React, { useEffect, useState } from "react";
import zeroTypingLogo from "../../Asset/logo.png";
import { HashLink as Link } from "react-router-hash-link";

const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if (currentScrollY > lastScrollY && currentScrollY > 50) {
      // User is scrolling down
      setShowNavbar(false);
    } else if (currentScrollY < lastScrollY) {
      // User is scrolling up
      setShowNavbar(true);
    }

    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);
  return (
    <div
      className={`navbar  ${
        showNavbar ? "show" : "hide"
      } backdrop-blur-md bg-[#0d0d0d40] z-50 `}
    >
      <div className="flex items-center justify-between  lg:mx-10 mx-4 lg:py-6 py-4 ">
        <Link to="#home" smooth className="flex md:gap-3 gap-2 items-center ">
          <img src={zeroTypingLogo} className=" lg:h-14 md:h-10 h-8" alt="" />
        </Link>
        <div>
          <Link
            to="#footer"
            smooth
            className="bg-gradient-to-r from-[#5ECCFF] to-[#407BFF] text-white font-montserrat lg:py-4 md:py-3 py-2 lg:px-10 md:px-7 px-5 md:rounded-[10px] rounded-[7px]  lg:text-[1rem] md:text-[.8rem] text-[.65rem] font-medium outline-none"
          >
            Join our Waiting List!
          </Link>
          {/* <Link
            to="#footer"
            smooth
            className="group relative cursor-pointer overflow-hidden  whitespace-nowrap px-6 py-4 text-white [background:var(--bg)] [border-radius:var(--radius)] transition-all duration-300  flex justify-center"
            style={{
              "--spread": "90deg",
              "--shimmer-color": "#407BFF",
              "--radius": "100px",
              "--speed": "1.5s",
              "--cut": "0.1em",
              "--bg": "linear-gradient(to right, #5ECCFF, #407BFF)",
            }}
          >
            <div className="absolute inset-0 overflow-hidden">
              <div className="absolute inset-[-100%] rotate-gradient">
                <div className="absolute inset-0 [background:conic-gradient(from_calc(270deg-(var(--spread)*0.5)),transparent_0,hsl(0_0%_100%/1)_var(--spread),transparent_var(--spread))]"></div>
              </div>
            </div>
            <div className="absolute [background:var(--bg)] [border-radius:var(--radius)] [inset:var(--cut)]"></div>
            <span className="z-10 w-48 whitespace-pre bg-gradient-to-b from-black from-30% to-gray-300/80 bg-clip-text text-center text-sm font-semibold leading-none tracking-tight text-white">
              Join our Waiting List!
            </span>
          </Link> */}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
